<template>
  <div>
    <h3 class="section_title">
      {{ title }}
    </h3>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainTitle",
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.section_title {
  color: rgb(var(--v-theme-title-color)) !important;
  &.center_text {
    text-align: center;
  }
}
</style>
