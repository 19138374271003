<template>
  <div class="main_definition_wrapper">
    <h5 class="title_color mb-0 text-capitalize main_definition_title">
      {{ title }}
    </h5>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "SectionDefinition",
  props: {
    title: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.main_definition_wrapper {
  .main_definition_title {
    font-size: 1.75rem;
  }
  &.sm_section {
    .main_definition_title {
      font-size: 17px;
    }
  }
}
</style>
