<template>
  <div class="d-flex gap-1 breadcrumb_wrapper align-items-center">
    <div v-for="(route, index) in mainPages" :key="index">
      <router-link
        class="router_link text-capitalize description_color"
        :to="{ name: `${route.to}` }"
        >{{ $t(`${route.name}`) }}</router-link
      >
      /
    </div>
    <span class="d-block description_color main_page_name text-capitalize">{{
      pageName
    }}</span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "BreadcrumbPaths",

  props: {
    mainPages: {
      type: Array,
    },
    pageName: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.breadcrumb_wrapper {
  .main_page_name {
    color: rgb(var(--v-theme-primary)) !important;
  }
  .main_page_name,
  .router_link {
    font-size: 14px;
  }
  .router_link {
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}
</style>
