import axiosInstance from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    branchesList: [],
    jobsList: [],
    departmentsList: [],
    countriesList: [],
    citiesList: [],
    nationalitiesList: [],
    specializationsList: [],
    classificationsList: [],
    dashboard: {},
  },
  getters: {
    getBranchesList(state) {
      return state.branchesList;
    },
    getJobsList(state) {
      return state.jobsList;
    },
    getDashboard(state) {
      return state.dashboard;
    },
  },
  mutations: {
    SET_BRANCHES_LIST(state, list) {
      state.branchesList = list;
    },
    SET_JOBS_LIST(state, list) {
      state.jobsList = list;
    },
    SET_DEPARTMENTS_LIST(state, list) {
      state.departmentsList = list;
    },
    SET_COUNTRIES_LIST(state, list) {
      state.countriesList = list;
    },
    SET_CITIES_LIST(state, list) {
      state.citiesList = list;
    },
    SET_NATIONALITIES_LIST(state, list) {
      state.nationalitiesList = list;
    },
    SET_SPECIALIZATION_LIST(state, list) {
      state.specializationsList = list;
    },
    SET_CLASSIFICATION_LIST(state, list) {
      state.classificationsList = list;
    },
    SET_DASHBOARD(state, items) {
      state.dashboard = items;
    },
  },
  actions: {
    // Handler dashboard
    async getDashboardData({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`dashboard`)
          .then((response) => {
            commit("SET_DASHBOARD", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get All Branches --  For dropdown select
    async getBranches({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`branches/select`)
          .then((response) => {
            commit("SET_BRANCHES_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get All Branches --  For dropdown select
    async getJobs({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`jobs/select`)
          .then((response) => {
            commit("SET_JOBS_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get All Branches --  For dropdown select
    async getDepartments({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`departments/select`)
          .then((response) => {
            commit("SET_DEPARTMENTS_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getCountries({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`countries/select`)
          .then((response) => {
            commit("SET_COUNTRIES_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getCities({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`cities/select`)
          .then((response) => {
            commit("SET_CITIES_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getNationalities({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`nationalities/select`)
          .then((response) => {
            commit("SET_NATIONALITIES_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getSpecializations({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`specializations/select`)
          .then((response) => {
            commit("SET_SPECIALIZATION_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getClassifications({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`classifications/select`)
          .then((response) => {
            commit("SET_CLASSIFICATION_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
