import Cookies from "js-cookie";

const tokenKey = "isLogged";
const userToken = "userToken";
const authUser = "authUser";
const status = "status";

export function getToken() {
  return Cookies.get(userToken);
}

export function getStatus() {
  return Cookies.get(status);
}

export function setStatus(status) {
  return Cookies.set(status, status);
}

export function setToken(token) {
  return Cookies.set(userToken, token);
}

export function authUserData(user) {
  return Cookies.set(authUser, user);
}
