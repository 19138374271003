import axiosInstance from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    branchesList: [],
    branchDetails: [],
    branchWorkHours: {},
    branchWorkHoursLast: {},
  },
  getters: {
    getBranchesList(state) {
      return state.branchesList;
    },
    getBranchDetails(state) {
      return state.branchDetails;
    },
    getBranchWorkHours(state) {
      return state.branchWorkHours;
    },
    getBranchWorkHoursLst(state) {
      return state.branchWorkHoursLast;
    },
  },
  mutations: {
    SET_BRANCHES_LIST(state, list) {
      state.branchesList = list;
    },
    SET_BRANCHES_Details(state, list) {
      state.branchDetails = list;
    },
    SET_BRANCHES_WORK_HOURS(state, items) {
      state.branchWorkHours = items;
    },
    SET_BRANCHES_WORK_HOURS_LAST(state, items) {
      state.branchWorkHoursLast = items;
    },
  },
  actions: {
    // Handler Get All Branches
    async getBranches({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`branches`, {
            params: {
              limit: payload ? payload.limit : 10,
              offest: 1,
              sort: "ASC",
              paginate: "true",
              field: "id",
              deleted: "all", //all,deleted
              resource: "all", //all,custom
              resource_columns: ["id"],
              columns: payload ? payload.columns : [],
              operand: payload ? payload.operand : [],
              column_values: payload ? payload.column_values : [],
            },
          })
          .then((response) => {
            commit("SET_BRANCHES_LIST", response.data.data.data);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get Branch
    async getBranchDetails({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`branches/${id}`)
          .then((response) => {
            commit("SET_BRANCHES_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get Branch Work Hours
    async getBranchWorkHours({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`branches/work-hours`, payload)
          .then((response) => {
            commit("SET_BRANCHES_WORK_HOURS", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get Branch Work Hours => YES
    async getBranchWorkHoursLast({ commit }, id) {
      if (id) {
        return await new Promise((resolve, reject) => {
          axiosInstance
            .get(`get-work-hours?id=${id}`)
            .then((response) => {
              commit("SET_BRANCHES_WORK_HOURS_LAST", response.data.data);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        return await new Promise((resolve, reject) => {
          axiosInstance
            .get(`get-work-hours`)
            .then((response) => {
              commit("SET_BRANCHES_WORK_HOURS_LAST", response.data.data);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
      // return await new Promise((resolve, reject) => {
      //   axiosInstance
      //     .post(`get-work-hours?id=${id}`)
      //     .then((response) => {
      //       commit("SET_BRANCHES_WORK_HOURS_LAST", response.data.data);
      //       resolve(response);
      //     })
      //     .catch((error) => {
      //       reject(error);
      //     });
      // });
    },
    // Handler Add Branch
    async addBranch({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`branches`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Delete Branch
    async deleteBranch({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .delete(`branches/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Handler Get All Branches
    async exportData({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`branches/export`)
          .then((response) => {
            console.log("response export", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Handler Add Work Hours
    async addWorkHours({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`branches/work-hours`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
