import { createApp } from "vue";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

const app = createApp();

export default createVuetify({
  components,
  directives,
  theme: {
    // dark: false, // Default to light mode
    defaultTheme: localStorage.getItem("Done-App-Theme") || "light",
    themes: {
      light: {
        colors: {
          primary: "#55dba8",
          // primary: process.env.VUE_APP_LIGHT_PRIMARY_COLOR,
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#e61445",
          info: "#2196F3",
          success: "#06d6a0",
          warning: "#F8c23a",
          purple: "#E040FB",
          "gray-light": "#f4f7fe",
          "gray-dark": "#e8ecef",
          "container-color": process.env.VUE_APP_LIGHT_CONTAINER_COLOR,
          "sidebar-color": process.env.VUE_APP_LIGHT_SIDEBAR_COLOR,
          "dropdown-cards-color":
            process.env.VUE_APP_LIGHT_CARDS_DROPDOWNS_COLOR,
          "title-color": process.env.VUE_APP_LIGHT_TITLE_COLOR,
          "text-color": process.env.VUE_APP_LIGHT_TEXT_COLOR,
          gray: "#808080",
        },
      },
      dark: {
        colors: {
          primary: process.env.VUE_APP_DARK_PRIMARY_COLOR,
          secondary: "#424242",
          accent: "#FF4081",
          error: "#e61445",
          info: "#2196F3",
          success: "#06d6a0",
          warning: "#F8c23a",
          purple: "#E040FB",
          "gray-light": "#f4f7fe",
          "gray-dark": "#e8ecef",
          "container-color": process.env.VUE_APP_DARK_CONTAINER_COLOR,
          "sidebar-color": process.env.VUE_APP_DARK_SIDEBAR_COLOR,
          "dropdown-cards-color":
            process.env.VUE_APP_DARK_CARDS_DROPDOWNS_COLOR,
          "title-color": process.env.VUE_APP_DARK_TITLE_COLOR,
          "text-color": process.env.VUE_APP_DARK_TEXT_COLOR,
          gray: "#808080",
        },
      },
    },
  },
});
