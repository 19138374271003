<template>
  <div class="global_filter_and_search mb-8">
    <div>
      <div class="search_wrapper custom_search_wrapper">
        <v-text-field
          placeholder="Search"
          hide-details="auto"
          prepend-inner-icon="mdi mdi-magnify"
          flat
          border
          v-model="selectedModelValue"
        ></v-text-field>
        <AppButton
          labelText="Search"
          @click="filterData"
          :filter="true"
          :disabled="filterLoading || !selectedModelValue"
          :loading="filterLoading"
        >
          <v-icon icon="mdi mdi-magnify" v-if="!filterLoading"></v-icon>
        </AppButton>
      </div>
    </div>

    <div class="buttons_container description_color">
      <AppButton
        labelText="filter"
        :filter="true"
        v-if="showFilter"
        @click="openFilter"
        ><v-icon icon="mdi mdi-export"></v-icon
      ></AppButton>

      <AppButton
        @click="exportData"
        labelText="Export"
        :filter="true"
        v-if="showExport"
        :disabled="exportLoading"
        :loading="exportLoading"
        ><v-icon icon="mdi mdi-filter-check-outline"></v-icon
      ></AppButton>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch } from "vue";
export default defineComponent({
  name: "SearchAndFilter",
  props: {
    showExport: {
      type: Boolean,
      default: true,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    exportLoading: {
      type: Boolean,
      default: false,
    },
    filterLoading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Number, String],
    },
  },
  setup(props, { emit }) {
    const exportData = () => {
      emit("export");
    };
    const filterData = () => {
      emit("filterData");
    };
    const openFilter = () => {
      emit("openFilter");
    };

    const selectedModelValue = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    watch(
      () => selectedModelValue.value,
      (newvalue) => {
        emit("updateValue");
      }
    );
    return {
      exportData,
      selectedModelValue,
      filterData,
      openFilter,
    };
  },
});
</script>

<style lang="scss" scoped>
.global_filter_and_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // .search_wrapper {
  //   min-width: 400px;
  //   display: flex;
  //   gap: 10px;
  // }
  .buttons_container {
    display: flex;
    gap: 10px;
  }
}
</style>
