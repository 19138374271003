<template>
  <div class="main_activities_wrapper px-2 py-4">
    <div class="custom_card p-3 text-center">
      <h5 class="title_color mb-0 fs-3 text-capitalize">day date</h5>
      <span class="d-block mb-2 mt-3">Saturday september -23 2024</span>
      <span class="d-block">08:07:00 AM</span>
    </div>
    <!-- Start Activities -->
    <div class="activities_container mt-4">
      <SectionDefinition title="last activities" class="title_color">
        <p class="define_description mb-0">
          Lorem, ipsum dolor sit amet consectetur .
        </p>
      </SectionDefinition>
      <div class="">
        <div
          class="every_activity_card cursor-pointer d-flex justify-content-between shadow-sm mt-4 p-2 rounded"
          v-for="item in activities"
          :key="item.id"
        >
          <div class="part_one d-flex gap-3 align-items-center">
            <img
              draggable="false"
              class="user_img shadow-sm"
              :src="item.img"
              :alt="item.name"
            />
            <div class="name_status">
              <span class="name text-capitalize title_color mb-1 d-block">{{
                item.name
              }}</span>
              <div class="status d-flex gap-2 align-items-center">
                <i
                  class="pi pi-clock status_icon"
                  :style="{ color: item.code }"
                ></i>
                <span class="status_name" :style="{ color: item.code }">{{
                  item.status
                }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-end">
            <span class="time">{{ item.time }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Activities -->
    <!-- Start Vacations -->
    <div class="vacations_container mt-4">
      <div class="d-flex justify-content-between align-items-center">
        <SectionDefinition title="Next Vacations" class="title_color">
          <p class="define_description mb-0">
            Lorem, ipsum dolor sit amet consectetur .
          </p>
        </SectionDefinition>
        <span
          class="icon_container cursor-pointer bg-white d-flex justify-content-center align-items-center shadow-sm"
        >
          <i class="pi pi-cog status_icon"></i>
        </span>
      </div>
      <!-- Vacations -->
      <div
        class="every_vacation d-flex gap-3 mt-4"
        v-for="item in vacations"
        :key="item.id"
      >
        <div class="dates bg-white shadow-sm text-center rounded">
          <span class="d-block num">{{ item.num }}</span>
          <span class="d-block day text-capitalize">{{ item.day }}</span>
        </div>
        <SectionDefinition :title="item.name" class="title_color sm_section">
          <p class="define_description mb-0 mt-2">{{ item.date }}</p>
        </SectionDefinition>
      </div>
    </div>
    <!-- End Vacations -->
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "MainActivities",
  setup() {
    const activities = ref([
      {
        id: 1,
        name: "moustafa ali",
        time: "09:45 Am",
        img: require("@/assets/images/group.png"),
        status: "Dismissal registration",
        code: "#EF4136",
      },
      {
        id: 2,
        name: "moustafa ali",
        time: "09:45 Am",
        img: require("@/assets/images/group.png"),
        status: "A vacation request",
        code: "#3A5FF8",
      },
      {
        id: 3,
        name: "moustafa ali",
        time: "09:45 Am",
        img: require("@/assets/images/group.png"),
        status: "Dismissal registration",
        code: "#F79425",
      },
      {
        id: 4,
        name: "moustafa ali",
        time: "09:45 Am",
        img: require("@/assets/images/group.png"),
        status: "Dismissal registration",
        code: "#06D6A0",
      },
      {
        id: 5,
        name: "moustafa ali",
        time: "09:45 Am",
        img: require("@/assets/images/group.png"),
        status: "Dismissal registration",
        code: "#06D6A0",
      },
    ]);
    const vacations = ref([
      {
        id: 1,
        name: "vacation number one",
        num: "07",
        day: "Saturday",
        date: "12-12-2014",
      },
      {
        id: 2,
        name: "vacation number two",
        num: "08",
        day: "sunday",
        date: "12-12-2014",
      },
      {
        id: 3,
        name: "vacation number three",
        num: "20",
        day: "monday",
        date: "12-12-2014",
      },
    ]);
    return { activities, vacations };
  },
});
</script>

<style lang="scss" scoped>
.main_activities_wrapper {
  .every_activity_card {
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #fff;
      transform: translateY(-5px);
      box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1) !important;
    }
    .part_one {
      .user_img {
        // width: 40px;
        // height: 40px;
        // border-radius: 50%;
        @include makeRadius(40px, 40px);
      }
    }
    .name_status {
      .name {
        font-weight: 500;
        font-size: 15px;
      }
      .status {
        font-size: 12px;
        .status_icon {
          font-size: 12px;
        }
        .status_name {
          font-size: 12px;
        }
      }
    }
    .time {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .vacations_container {
    .icon_container {
      @include makeRadius(30px, 30px);
    }
    .every_vacation {
      .dates {
        @include makeRadius(60px, 55px);
        .num {
          font-size: 20px;
          font-weight: 500;
        }
        .day {
          font-size: 12px;
        }
      }
      .define_description {
        font-size: 12px;
      }
    }
  }
}
</style>
