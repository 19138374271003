<template>
  <div class="text-center">
    <v-dialog v-model="modelValue" width="auto" persistent>
      <v-card max-width="700" width="500">
        <v-card-text class="mt-5">
          <div class="text-center">
            <img src="@/assets/images/delete.gif" alt="delete img" />
            <p>Are you sure you want delete this {{ title }}</p>
          </div>
        </v-card-text>
        <template v-slot:actions>
          <div class="global_app_footer with_delete">
            <AppButton
              labelText="Cancel"
              class="cancel"
              @click="close"
            ></AppButton>
            <AppButton
              :disabled="loading"
              :loading="loading"
              labelText="Confirm"
              @click="confirmDelete"
            ></AppButton>
          </div>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "DeleteConfirm",
  props: {
    modelValue: Boolean,
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const toggleDialog = () => {
      emit("update:modelValue");
    };
    const close = () => {
      emit("update:modelValue", false);
    };

    const confirmDelete = () => {
      emit("confirmDelete");
      close();
    };
    return {
      toggleDialog,
      close,
      confirmDelete,
    };
  },
});
</script>

<style lang="scss" scoped></style>
