<template>
  <div :class="[
    'main_top_navbar',
    'p-2',
    { sidebar_closed: showSidebar },
    { language_switched_to_ar: getLang == 'ar' },
  ]">
    <div class="container">
      <div class="px-md-5">
        <div class="container_wrapper bg-sidebar-color d-flex align-items-center justify-content-between">
          <div>
            <h5 class="mb-1 title_color text-capitalize">
              {{ $t("Dashboard") }}
            </h5>

            <!-- Breadcrumbs for different views -->
            <p v-if="routeName == 'HomeView'" class="global_sub_title mb-0">
              {{ $t("welcome back") }}
            </p>
            <BreadcrumbPaths v-if="routeName == 'OrdersView'" :mainPages="sharedRoutePage" :pageName="$t('Orders')">
            </BreadcrumbPaths>
            <BreadcrumbPaths v-if="routeName == 'BranchesView'" :mainPages="sharedRoutePage" :pageName="$t('Branches')">
            </BreadcrumbPaths>
            <BreadcrumbPaths v-if="routeName == 'VacationsView'" :mainPages="sharedRoutePage"
              :pageName="$t('Annual vacations')"></BreadcrumbPaths>
            <BreadcrumbPaths v-if="routeName == 'EmployeesView'" :mainPages="sharedRoutePage"
              :pageName="$t('Employees')"></BreadcrumbPaths>
            <BreadcrumbPaths v-if="routeName == 'DepartmentsView'" :mainPages="sharedRoutePage"
              :pageName="$t('Departments')"></BreadcrumbPaths>
            <BreadcrumbPaths v-if="routeName == 'AccountSettings'" :mainPages="sharedRoutePage"
              :pageName="$t('Account Settings')"></BreadcrumbPaths>
            <BreadcrumbPaths v-if="routeName == 'AddEmploye'" :mainPages="addEmployeesRoutePage"
              :pageName="hasIdAtEnd ? $t('Edit Employ') : $t('Add Employ')"></BreadcrumbPaths>
          </div>

          <!-- User, Language, and Notifications -->
          <div class="d-flex align-items-center gap-3">
            <!-- Notifications -->
            <div>
              <v-menu transition="scale-transition" offset-y min-width="300" @open="markAllAsRead">
                <template v-slot:activator="{ props }">
                  <v-btn class="plain" flat v-bind="props">
                    <v-icon icon="mdi mdi-bell-outline" size="24"></v-icon>
                    <v-badge v-if="unreadCount > 0" color="red" :content="unreadCount" overlap></v-badge>
                  </v-btn>
                </template>

                <v-list class="notification-list">
                  <!-- Check if notifications list is empty -->
                  <v-list-item v-if="notifications.length === 0">
                    <v-list-item-title class="p-4 text-center text-muted">
                      <v-icon icon="mdi mdi-information-outline" class="mr-2"></v-icon>
                      No notifications available
                    </v-list-item-title>
                  </v-list-item>

                  <!-- Display notifications if they exist -->
                  <v-list-item v-for="notification in notifications" :key="notification.id" class="notification-item">
                    <v-list-item-content>
                      <v-list-item-title class="notification-title">
                        {{ notification.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="notification-body">
                        {{ notification.body }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="grey" size="16" icon="mdi-chevron-right"></v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import echo from '../../utils/echo'; // Adjust the path as necessary
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "TopNavbarWithDescription",

  setup() {
    const { locale } = useI18n();
    const showSidebar = computed(() => store.state.showSidebar);
    const getLang = computed(() => locale.value);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const routeName = ref(route.name);
    const routePath = ref(route.path);
    const notifications = ref([]); // To store incoming notifications
    const unreadCount = ref(0); // To store the count of unread notifications

    const hasIdAtEnd = computed(() => {
      const path = route.path;
      const regex = /\/employees\/add-employ\/\d+$/;
      return regex.test(path);
    });

    const toggleSidebar = () => {
      store.dispatch("toggleSidebar");
    };


    const markAllAsRead = () => {
      unreadCount.value = 0;
    };

    // Routes for breadcrumb
    const sharedRoutePage = ref([
      {
        to: "HomeView",
        name: "Home",
      },
    ]);

    const addEmployeesRoutePage = ref([
      {
        to: "HomeView",
        name: "Home",
      },
      {
        to: "EmployeesView",
        name: "Orders",
      },
    ]);

    // Listen for new notifications
    onMounted(() => {
      echo.channel('requests')
        .listen('.new-request-created', (event) => {
          console.log("New Request Notification received: ", event);
          notifications.value.push(JSON.stringify(event));

          // Increment the unread count
          unreadCount.value += 1;
        });
    });

    watch(
      () => route.name,
      (newName) => {
        routeName.value = newName;
      }
    );

    watch(
      () => route.path,
      (newPath) => {
        routePath.value = newPath;
      }
    );


    return {
      showSidebar,
      toggleSidebar,
      markAllAsRead,
      sharedRoutePage,
      getLang,
      routeName,
      routePath,
      addEmployeesRoutePage,
      hasIdAtEnd,
      notifications,
      unreadCount,
    };
  },
});

</script>

<style lang="scss" scoped>
.main_top_navbar {
  border-radius: 10px;
  position: fixed;
  width: calc(100% - 280px);
  top: 20px;
  z-index: 1000;
  left: 280px;

  &.sidebar_closed {
    left: 0;
    width: 100%;
  }

  &.language_switched_to_ar {
    left: auto;
    right: 280px;

    &.sidebar_closed {
      left: auto;
      right: 0;
      width: 100%;
    }
  }

  .container_wrapper {
    padding: 10px;
  }

  .v-btn .v-icon {
    --v-icon-size-multiplier: 0.9571428571;
  }

  .user_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .icon_nav_bar {
    transition: all 0.3s;

    &:hover {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }

  .empty-notifications {
    text-align: center;
    color: rgb(var(--v-theme-secondary));
    padding: 15px 0;
  }
}

.link {
  text-decoration: none;
  color: rgb(var(--v-theme-title-color)) !important;
  transition: all 0.3s ease-in-out;
}

.notification-list {
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  padding: 16px;
  transition: background-color 0.3s ease;
}

.notification-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.notification-title {
  font-weight: 500;
  font-size: 14px;
}

.notification-body {
  font-size: 12px;
  color: #757575;
  margin-top: 4px;
}
</style>
