<template>
  <section class="main_auth_layout_wrapper bg-white px-4 py-7">
    <div class="container">
      <div class="part_one_nav">
        <AuthLayout></AuthLayout>
      </div>
      <div class="part_two_pages py-5">
        <main>
          <!-- <transition name="fade" mode="out-in"> -->
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
          <!-- </transition> -->
        </main>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AuthenticationLayout",
};
</script>

<style lang="scss" scoped>
.main_auth_layout_wrapper {
  min-height: 100vh;
  .part_two_pages {
    margin-top: 40px;
  }
}
.fade-enter-active,
.fade-leave-active {
  animation: fade-in-out 0.5s;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
