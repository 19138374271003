import axiosInstance from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    vacationsList: [],
    vacationDetails: [],
  },
  getters: {
    getVacationsList(state) {
      return state.vacationsList;
    },
    getVacationDetails(state) {
      return state.vacationDetails;
    },
  },
  mutations: {
    SET_VACATIONS_LIST(state, list) {
      state.vacationsList = list;
    },
    SET_VACATION_Details(state, list) {
      state.vacationDetails = list;
    },
  },
  actions: {
    // Handler Get All Vacations
    async getVacations({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`annual-vacations`, {
            params: {
              limit: payload ? payload.limit : 10,
              offest: 1,
              sort: "ASC",
              paginate: "true",
              field: "id",
              deleted: "all", //all,deleted
              resource: "all", //all,custom
              resource_columns: ["id"],
              columns: payload ? payload.columns : [],
              operand: payload ? payload.operand : [],
              column_values: payload ? payload.column_values : [],
            },
          })
          .then((response) => {
            commit("SET_VACATIONS_LIST", response.data.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Add Vacation
    async addVacation({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`annual-vacations`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Add Vacation
    async editVacation({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .put(`annual-vacations/${payload.id}`, payload.updateData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get Vacation
    async getVacationDetails({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`annual-vacations/${id}`)
          .then((response) => {
            commit("SET_VACATION_Details", response.data.data);
            console.log(response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Delete Vacation
    async deleteVacation({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .delete(`annual-vacations/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Export
    async exportData({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`vacations/export`)
          .then((response) => {
            console.log("response export", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
