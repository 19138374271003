import axiosInstance from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    departmentsList: [],
    departmentDetails: [],
    departmentsSelectList: [],
  },
  getters: {
    getDepartmentList(state) {
      return state.departmentsList;
    },
    getDepartmentDetails(state) {
      return state.departmentDetails;
    },
    getDepartmentSelectList(state) {
      return state.departmentsSelectList;
    },
  },
  mutations: {
    SET_DEPARTMENT_LIST(state, list) {
      state.departmentsList = list;
    },
    SET_DEPARTMENT_Details(state, list) {
      state.departmentDetails = list;
    },
    SET_DEPARTMENT_SELECT_LIST(state, list) {
      state.departmentsSelectList = list;
    },
  },
  actions: {
    // Handler Get All Department
    async getDepartments({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`department`, {
            params: {
              limit: payload ? payload.limit : 10,
              offest: 1,
              sort: "ASC",
              paginate: "true",
              field: "id",
              deleted: "all", //all,deleted
              resource: "all", //all,custom
              resource_columns: ["id"],
              columns: payload ? payload.columns : [],
              operand: payload ? payload.operand : [],
              column_values: payload ? payload.column_values : [],
            },
          })
          .then((response) => {
            commit("SET_DEPARTMENT_LIST", response.data.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get Department
    async getDepartmentDetails({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`department/${id}`)
          .then((response) => {
            commit("SET_DEPARTMENT_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Handler Add Department
    async addDepartment({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`department`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Department
    async editDepartment({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .put(`department/${payload.id}`, payload.updateData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Delete Department
    async deleteDepartment({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .delete(`department/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Export
    async exportData({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`departments/export`)
          .then((response) => {
            console.log("response export", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get All Employees Select
    async getDepartmentSelect({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`departments/select`)
          .then((response) => {
            commit("SET_DEPARTMENT_SELECT_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
