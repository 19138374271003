import axiosInstance from "@/utils/axios";
import Cookies from "js-cookie";
export default {
  namespaced: true,
  state: {
    userToken: null,
    authUser: {},
    // Profile
    profileData: {},
  },
  getters: {
    getUserToken(state) {
      return state.userToken;
    },
    getAuthUser(state) {
      return state.authUser;
    },
    getUserProfile(state) {
      return state.profileData;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.userToken = token;
    },
    SET_AUTH(state, user) {
      state.authUser = user;
    },
    SET_PROFILE_DATA(state, user) {
      state.profileData = user;
    },
  },
  actions: {
    // Handler Register User
    async handlerRegister({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("register", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Send Confirm Email Code
    async handlerConfirmEmail({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("send-code", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Email Code After Send
    async handlerUpdateEmail({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("update-email", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Login
    async handlerLogin({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("login", payload)
          .then((response) => {
            Cookies.set("userToken", response.data.data.access_token);
            Cookies.set("authUser", JSON.stringify(response.data.data.user));
            commit("SET_TOKEN", response.data.data.access_token);
            commit("SET_AUTH", JSON.stringify(response.data.data.user));
            // let userData = Cookies.get("authUser");
            // console.log("authUser", JSON.parse(userData));
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async handlerLogout({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("logout")
          .then((response) => {
            Cookies.remove("userToken", response.data.access_token);
            Cookies.remove("authUser", JSON.stringify(response.data.user));
            commit("SET_TOKEN", "");
            commit("SET_AUTH", {});

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // send code after register
    async handlerSendCode({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("send-code", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // send code after register
    async handlerCheckCode({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("check-code", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Get Profile
    async getProfile({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`profile`)
          .then((response) => {
            commit("SET_PROFILE_DATA", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Forget Password
    // send code after register
    async handlerSendCodeForgetPassword({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("reset", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // handlerChangePassword
    async handlerChangePassword({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post("confirm-reset", payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
