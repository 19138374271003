import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
// import VueCookieNext from "vue-cookie-next";
import PrimeVue from "primevue/config";
// import "primeicons/primeicons.css";
// import "primevue/resources/primevue.min.css";
// import "primevue/resources/themes/saga-blue/theme.css";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VuetifyPlugin from "./plugins/vuetify";

const app = createApp(App);

import reuseableComponents from "@/components/reuseable-components/reuseableComponents";
reuseableComponents.forEach((component) => {
  app.component(component.name, component);
});

app.use(PrimeVue);
app.use(VuetifyPlugin);
app.use(i18n);
// app.use(VueCookieNext);
// app.use(BootstrapVue);
// app.use(IconsPlugin);

import Button from "primevue/button";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; // optional
import Row from "primevue/row"; // optional
import SpeedDial from "primevue/speeddial";
import Dropdown from "primevue/dropdown";

app.component("Button", Button);
app.component("Password", Password);
app.component("InputText", InputText);
app.component("IconField", IconField);
app.component("InputIcon", InputIcon);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("SpeedDial", SpeedDial);
app.component("Dropdown", Dropdown);

app.use(store).use(router).mount("#app");
