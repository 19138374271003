<template>
  <header
    class="main_auth_layout bg-sidebar-color d-flex justify-content-between align-items-center py-3"
  >
    <router-link class="link text-capitalize" :to="{ name: 'HomeView' }">
      <img
        draggable="false"
        class="logo"
        src="@/assets/images/new logo.jpeg"
        alt="logo"
      />
    </router-link>

    <div class="routes d-flex align-items-center">
      <router-link
        class="links text-capitalize py-2"
        :to="{ name: 'PolicyView' }"
      >
        {{ $t("Privacy policy") }}
      </router-link>
      <router-link
        class="links text-capitalize py-2 px-3"
        :to="{ name: 'TermsView' }"
      >
        {{ $t("Terms conditions") }}
      </router-link>
      <router-link
        class="links text-capitalize py-2"
        :to="{ name: 'SupportedView' }"
      >
        {{ $t("Customer support") }}
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style lang="scss" scoped>
.main_auth_layout {
  gap: 1rem;
  .logo {
    height: 70px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  .routes {
    gap: 1.25rem;
    .links {
      text-decoration: none;
      color: rgb(var(--v-theme-title-color)) !important;
      transition: all 0.3s ease-in-out;
      display: flex;

      align-items: center;
      @media (min-width: 320px) and (max-width: 567px) {
        font-size: 14px;
      }

      &:hover,
      &.router-link-exact-active {
        color: rgb(var(--v-theme-primary)) !important;
      }
    }
  }
}
</style>
