import { createI18n } from "vue-i18n";
import Cookies from "js-cookie";

const messages = {
  en: {
    ...require("@/locales/en.json"),
  },
  ar: {
    ...require("@/locales/ar.json"),
  },
};

const i18n = createI18n({
  legacy: false,
  locale: Cookies.get("done-lang") || "en", // Default locale
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages,
});

export default i18n;
