import axios from "axios";

import { getToken } from "@/plugins/cookies";
import { useStore } from "vuex";
import Cookies from "js-cookie";
const store = useStore();
// import { store } from "@/store/index.js";
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 5000, // Timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
    language: Cookies.get("done-lang") || "en",
    // "Access-Control-Allow-Origin": "*",
    // withCredentials: false,
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // Add any other default headers here
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Modify request config (e.g., add authentication token)
    const userToken = getToken();
    if (userToken) {
      config.headers["Authorization"] = "Bearer " + userToken;
      config.headers["Content-Type"] = "application/json";
      // config.headers["withCredentials"] = false;
      // config.headers['Accept'] = 'application/json';

      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["Access-Control-Allow-Methods"] =
        "GET,PUT,POST,DELETE,PATCH,OPTIONS";

      // config.headers['language']= Cookies.get("lang") ||'ar'
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Modify response data
    return response;
  },
  (error) => {
    if (error.response) {
      const errorMessage = error.response.data.message || "An error occurred";
      console.log("error.response -- axios", error.response.data.message);
      console.log("error.response -- axios", error.response.data);
      console.log("store", store);
      // store.commit("showSnackbar", { text: errorMessage, color: "error" });
    } else {
      // store.commit("showSnackbar", { text: "Network Error", color: "error" });
    }
    // Handle response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
