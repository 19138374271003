import axiosInstance from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    ordersList: [],
    orderDetails: {},
    modelList: [
      {
        name: "Loan Request",
        id: "LoanRequest",
      },
      {
        name: "Job Task Request",
        id: "JobTaskRequest",
      },
      {
        name: "Extra Work Request",
        id: "ExtraWorkRequest",
      },
      {
        name: "Training Course Request",
        id: "TrainingCourseRequest",
      },
      {
        name: "Resignation",
        id: "Resignation",
      },
      {
        name: "Salary Increase",
        id: "SalaryIncrease",
      },
      {
        name: "Purchase",
        id: "Purchase",
      },
      {
        name: "Maintenance",
        id: "Maintenance",
      },
      {
        name: "Financial Privilege",
        id: "FinancialPrivilege",
      },
      {
        name: "Transfer Requests",
        id: "TransferRequests",
      },
      {
        name: "Work Delegations",
        id: "WorkDelegations",
      },
      {
        name: "Financial Custody",
        id: "FinancialCustody",
      },
      {
        name: "Salary Disbursement",
        id: "SalaryDisbursement",
      },
      {
        name: "Salary Definition",
        id: "SalaryDefinition",
      },
      {
        name: "DuesSettlement",
        id: "DuesSettlement",
      },
      {
        name: "Clearance",
        id: "Clearance",
      },
      {
        name: "Permission Request",
        id: "PermissionRequest",
      },
      {
        name: "Vacation Request",
        id: "VacationRequest",
      },
      {
        name: "Other",
        id: "Other",
      },
    ],
  },
  getters: {
    getOrdersList(state) {
      return state.ordersList;
    },
    getOrderDetails(state) {
      return state.orderDetails;
    },
    getModelList(state) {
      return state.modelList;
    },
  },
  mutations: {
    SET_ORDERS_LIST(state, list) {
      state.ordersList = list;
    },
    SET_ORDER_Details(state, list) {
      state.orderDetails = list;
    },
  },
  actions: {
    // Handler Get All Orders
    async getOrders({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`orders`, {
            params: {
              limit: payload ? payload.limit : 10,
              offest: 1,
              sort: "ASC",
              paginate: "true",
              field: "id",
              deleted: "all", //all,deleted
              resource: "all", //all,custom
              resource_columns: ["id"],
              columns: payload ? payload.columns : [],
              operand: payload ? payload.operand : [],
              column_values: payload ? payload.column_values : [],
            },
          })
          .then((response) => {
            commit("SET_ORDERS_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Update Orders
    async updateOrders({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`orders/update-status`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get Orders orders/2?model=App\\Models\\LoanRequest
    async getOrdersDetails({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`orders/order-show/${payload.id}/${payload.model}`)
          .then((response) => {
            commit("SET_ORDER_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async addReplayMsg({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`orders/add-reply`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async getOrderPdfReport({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`pdfs/loan-request/${id}`)
          .then((response) => {
            // commit("SET_ORDER_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //  Employee pdf warning
    async getEmployeePdfReport({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`pdfs/warning/${id}`)
          .then((response) => {
            // commit("SET_ORDER_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //  Vacation pdf
    async getVacationPdfReport({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`pdfs/vacation/${id}`)
          .then((response) => {
            // commit("SET_ORDER_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //  evaluation pdf
    async getEvaluationPdfReport({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`pdfs/evaluation/${id}`)
          .then((response) => {
            // commit("SET_ORDER_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //  joining pdf
    async getJoiningPdfReport({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`pdfs/joining/${id}`)
          .then((response) => {
            // commit("SET_ORDER_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
