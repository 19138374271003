<template>
  <div class="main_home_wrapper">
    <div class="bg-gray-light rounded p-4 mb-8">
      <div class="mb-5 d-flex align-items-start justify-content-between">
        <div>
          <h5 class="mb-0 title_color text-capitalize">
            {{ $t("today statistics") }}
          </h5>
          <p class="global_sub_title mb-0 mt-1">
            {{ $t("Summary of today report") }}
          </p>
        </div>
        <!-- <AppButton :labelText="$t('Show All')" class="bg-white"></AppButton> -->
      </div>
      <div
        class="details_for_main_section d-flex align-items-start justify-content-between"
      >
        <div>
          <h6 class="title_color mb-2 text-capitalize">
            {{ $t("the audience") }}
          </h6>
          <span class="text-success text-center d-block fs-4">
            {{ dashboard.attendance_stats?.presence_percentage?.toFixed(2) }}
            %</span
          >
        </div>
        <div>
          <h6 class="title_color mb-2 text-capitalize">{{ $t("Absence") }}</h6>
          <span class="text-error text-center d-block fs-4"
            >{{
              dashboard.attendance_stats?.absence_percentage?.toFixed(2)
            }}%</span
          >
        </div>
        <div>
          <h6 class="title_color mb-2 text-capitalize">
            {{ $t("Arrival late") }}
          </h6>
          <span class="text-warning text-center d-block fs-4"
            >{{
              dashboard.attendance_stats?.late_percentage?.toFixed(2)
            }}%</span
          >
        </div>
      </div>
    </div>
    <div class="bg-gray-light rounded p-4 mt-5 d-none">
      <h4 class="description_color fs-3 text-capitalize">dashboard info</h4>
      <div class="row">
        <!-- Branches -->
        <div class="col-md-3 mt-3">
          <div
            class="every_card_item text-capitalize bg-dropdown-cards-color shadow rounded py-2 px-3 d-flex align-items-center justify-content-between"
          >
            <div class="part_one">
              <span
                class="show_number d-block fs-3 mb-1 font-weight-bold font-italic title_color"
              >
                {{ dashboard.branchesCount }}
              </span>
              <h4 class="description_color mb-0 fs-5">Branches</h4>
            </div>
            <div
              class="part_two warning d-flex align-items-center justify-content-center shadow-sm"
            >
              <v-icon icon="mdi mdi-source-branch-check card_icon"></v-icon>
            </div>
          </div>
        </div>
        <!-- Orders -->
        <div class="col-md-3 mt-3">
          <div
            class="every_card_item text-capitalize bg-dropdown-cards-color shadow rounded py-2 px-3 d-flex align-items-center justify-content-between"
          >
            <div class="part_one">
              <span
                class="show_number d-block fs-3 mb-1 font-weight-bold font-italic title_color"
                >{{ dashboard.ordersCount }}</span
              >
              <h4 class="description_color mb-0 fs-5">Orders</h4>
            </div>
            <div
              class="part_two success d-flex align-items-center justify-content-center shadow-sm"
            >
              <v-icon
                icon="mdi-order-bool-ascending-variant card_icon"
              ></v-icon>
            </div>
          </div>
        </div>

        <!-- Employees -->
        <div class="col-md-3 mt-3">
          <div
            class="every_card_item text-capitalize bg-dropdown-cards-color shadow rounded py-2 px-3 d-flex align-items-center justify-content-between"
          >
            <div class="part_one">
              <span
                class="show_number d-block fs-3 mb-1 font-weight-bold font-italic title_color"
              >
                {{ dashboard.employeesCount }}</span
              >
              <h4 class="description_color mb-0 fs-5">Employees</h4>
            </div>
            <div
              class="part_two purple d-flex align-items-center justify-content-center shadow-sm"
            >
              <v-icon icon="mdi mdi-account-group card_icon"></v-icon>
            </div>
          </div>
        </div>
        <!-- Employees -->
        <div class="col-md-3 mt-3">
          <div
            class="every_card_item text-capitalize bg-dropdown-cards-color shadow rounded py-2 px-3 d-flex align-items-center justify-content-between"
          >
            <div class="part_one">
              <span
                class="show_number d-block fs-3 mb-1 font-weight-bold font-italic title_color"
              >
                {{ dashboard.departmentsCount }}</span
              >
              <h4 class="description_color mb-0 fs-5">departments</h4>
            </div>
            <div
              class="part_two info d-flex align-items-center justify-content-center shadow-sm"
            >
              <v-icon icon="mdi mdi-account-group card_icon"></v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Active sessions -->
    <div class="active_sessions mb-8">
      <div class="row">
        <div class="col-md-5">
          <div
            class="employees_in_vacations bg-gray-light rounded p-4 global_users_container"
          >
            <div class="d-flex justify-content-between">
              <div class="mb-3">
                <h5 class="mb-0 title_color text-capitalize">
                  {{ $t("Active sessions") }}
                </h5>
                <p class="global_sub_title mb-0 mt-1">
                  {{ $t("employees now") }}
                </p>
              </div>
              <span>( 10 )</span>
            </div>
            <div class="main_user_container home_active_sessions">
              <div
                class="every_user d-flex justify-content-between align-items-end py-3 px-2 rounded"
                v-for="user in dashboard.active_sessions"
                :key="user.id"
              >
                <div class="part_one d-flex align-items-center gap-1">
                  <div class="img_wrapper">
                    <img
                      class="img"
                      v-if="user.image"
                      :src="user.image"
                      :alt="user.employee_name"
                    />
                    <v-icon v-else icon="mdi mdi-account icon_nav_bar"></v-icon>
                  </div>
                  <div>
                    <h6 class="title_color mb-1 text-capitalize name">
                      {{ user.employee_name }}
                    </h6>
                    <div class="status d-flex gap-1 align-items-center">
                      <span class="description_color">{{
                        user.employee_job
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="employees_in_vacations bg-gray-light rounded p-4">
            <div class="d-flex justify-content-between">
              <div class="mb-3">
                <h5 class="mb-0 title_color text-capitalize">
                  {{ $t("Working Hours") }}
                </h5>
                <p class="global_sub_title mb-0 mt-1">
                  {{ $t("Working Hours percentage") }}
                </p>
              </div>
              <span>( {{ $t("day") }} )</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Vacations  && Employees -->
    <div class="vacations_employees">
      <div class="row">
        <div class="col-md-8">
          <div
            class="employees_in_vacations bg-gray-light rounded p-4 global_users_container"
          >
            <div class="d-flex justify-content-between">
              <div class="mb-3">
                <h5 class="mb-0 title_color text-capitalize">
                  {{ $t("In Vacation") }}
                </h5>
                <p class="global_sub_title mb-0 mt-1">
                  {{ $t("In Vacation description") }}
                </p>
              </div>
              <span>( 2 )</span>
            </div>
            <div class="main_user_container">
              <div
                class="every_user d-flex justify-content-between align-items-end py-3 px-2 rounded"
                v-for="user in dashboard.employees_on_leave"
                :key="user.id"
              >
                <div class="part_one d-flex align-items-center gap-1">
                  <div class="img_wrapper">
                    <img
                      class="img"
                      v-if="user.image"
                      :src="user.image"
                      :alt="user.employee_name"
                    />
                    <v-icon v-else icon="mdi mdi-account icon_nav_bar"></v-icon>
                  </div>
                  <div>
                    <h6 class="title_color mb-1 text-capitalize name">
                      {{ user.employee_name }}
                    </h6>
                    <div class="status d-flex gap-1 align-items-center">
                      <span class="description_color">{{
                        user.vacation_days
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="part_two">
                  <span class="description_color">{{
                    user.vacation_name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="bg-gray-light rounded p-4">
            <div class="d-flex justify-content-between">
              <div class="mb-3">
                <h5 class="mb-0 title_color text-capitalize">
                  {{ $t("Employees") }}
                </h5>
                <p class="global_sub_title mb-0 mt-1">
                  {{ $t("Employees number") }}
                </p>
              </div>
              <span style="min-width: 40px"
                >( {{ dashboard.employees_count }} )</span
              >
            </div>
            <div class="d-flex justify-content-center mt-3">
              <AppButton
                :labelText="$t('Add')"
                @click="switchToAddEmploy"
              ></AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HomeView",
  setup() {
    const store = useStore();
    const router = useRouter();
    const dashboard = computed(() => {
      return store.getters["shared/getDashboard"];
    });

    const users = ref([
      {
        id: 1,
        name: "test user",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "just day",
        time: "vacation reason",
      },
      {
        id: 2,
        name: "test user2",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "just day",
        time: "vacation reason",
      },
    ]);
    const usersActiveSessions = ref([
      {
        id: 1,
        name: "Mohamed Almearag",
        img: require("@/assets/images/group.png"),
        status_name: "Frontend developer",
      },
      {
        id: 2,
        name: "Mostafa ali",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status_name: "Frontend developer",
      },
      {
        id: 3,
        name: "Mohamed Almearag",
        img: require("@/assets/images/group.png"),
        status_name: "Frontend developer",
      },
      {
        id: 4,
        name: "Mostafa ali",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status_name: "Frontend developer",
      },
      {
        id: 5,
        name: "Mohamed Almearag",
        img: require("@/assets/images/group.png"),
        status_name: "Frontend developer",
      },
      {
        id: 6,
        name: "Mostafa ali",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status_name: "Frontend developer",
      },
    ]);
    const switchToAddEmploy = () => {
      router.push({ name: "AddEmploye" });
    };
    onMounted(() => {
      store.dispatch("shared/getDashboardData");
    });
    return { dashboard, users, usersActiveSessions, switchToAddEmploy };
  },
});
</script>

<style lang="scss" scoped>
.main_home_wrapper {
  .welcome_user {
    .welcome_back {
      color: $primary_color;
      font-size: 2em;
      text-transform: uppercase;
      color: transparent;
      text-shadow: -1px -1px 1px $primary_color, 1px -1px 1px $primary_color,
        -1px 1px 1px $primary_color, 1px 1px 1px $primary_color;
      letter-spacing: 5px;
    }
  }
  .every_card_item {
    transition: all 0.3s ease-in-out;
    position: relative;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    .part_one {
    }
    .part_two {
      background-color: rgb(var(--v-theme-info), 0.2) !important;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      .card_icon {
        color: #fff;
        color: rgb(var(--v-theme-info)) !important;
      }
      &.success {
        background-color: rgb(var(--v-theme-success), 0.2) !important;
        .card_icon {
          color: rgb(var(--v-theme-success)) !important;
        }
      }
      &.warning {
        background-color: rgb(var(--v-theme-warning), 0.2) !important;
        .card_icon {
          color: rgb(var(--v-theme-warning)) !important;
        }
      }
      &.purple {
        background-color: rgb(var(--v-theme-purple), 0.2) !important;
        .card_icon {
          color: rgb(var(--v-theme-purple)) !important;
        }
      }
    }
    &::before,
    &::after {
      height: 1px;
      content: "";
      width: 0;
      background-color: $primary_color;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: all 0.3s ease-in-out;
    }
    &::after {
      right: 0;
      top: 0;
      left: auto;
    }
    &:hover {
      transform: translateY(-5px);
      border-right-color: $primary_color;
      border-left-color: $primary_color;
      overflow: hidden;
      &::before,
      &::after {
        width: 100%;
      }
    }
  }
}
</style>
