<template>
  <v-menu v-model="isMenuOpen" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-text-field
        :label="label"
        :model-value="formattedDate"
        readonly
        v-bind="props"
        variant="solo"
        hide-details
        @click="isMenuOpen = true"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="selectedDate"
      show-adjacent-months
      hide-actions
      title=""
      :color="color"
      @change="onDateSelected"
    >
    </v-date-picker>
  </v-menu>
</template>

<script setup>
import moment from "moment";
import { ref, computed, watch, defineProps, defineEmits } from "vue";

const { label, color, modelValue } = defineProps([
  "label",
  "color",
  "modelValue",
]);
const emit = defineEmits("update:modelValue");

const isMenuOpen = ref(false);
const selectedDate = ref(modelValue ? new Date(modelValue) : null);

const formattedDate = computed(() => {
  return selectedDate.value ? moment(selectedDate.value).format("YYYY-MM-DD") : "";
});

const onDateSelected = () => {
  if (selectedDate.value) {
    emit("update:modelValue", moment(selectedDate.value).format("YYYY-MM-DD"));
    isMenuOpen.value = false;
  }
};

watch(modelValue, (newDate) => {
  selectedDate.value = new Date(newDate);
});

watch(formattedDate, (newDate) => {
  emit("update:modelValue", newDate);
});
</script>
