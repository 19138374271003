import axiosInstance from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    employeesList: [],
    employeesSelectList: [],
  },
  getters: {
    getEmployeesList(state) {
      return state.employeesList;
    },
    getEmployeesSelectList(state) {
      return state.employeesSelectList;
    },
  },
  mutations: {
    SET_EMPLOYEES_LIST(state, list) {
      state.employeesList = list;
    },
    SET_EMPLOYEES_SELECT_LIST(state, list) {
      state.employeesSelectList = list;
    },
  },
  actions: {
    // Handler Get All Employees
    async getEmployees({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`employees`, {
            params: {
              limit: payload ? payload.limit : 10,
              offest: 1,
              sort: "ASC",
              paginate: "true",
              field: "id",
              deleted: "all", //all,deleted
              resource: "all", //all,custom
              resource_columns: ["id"],
              columns: payload ? payload.columns : [],
              operand: payload ? payload.operand : [],
              column_values: payload ? payload.column_values : [],
            },
          })
          .then((response) => {
            commit("SET_EMPLOYEES_LIST", response.data.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get All Employees Select
    async getEmployeesSelect({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`employees/select`)
          .then((response) => {
            commit("SET_EMPLOYEES_SELECT_LIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Add Employ
    async addEmploy({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`employees`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Delete Employ
    async deleteEmploy({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .delete(`employees/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Get Employ
    async getEmploy({ commit, state }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`employees/${id}`)
          .then((response) => {
            console.log("employyyyyyyyyyyyyyyyyyy");
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Export
    async exportData({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`employees/export`)
          .then((response) => {
            console.log("response export", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
