<template>
  <Button
    v-if="!isLink"
    @click="handlerClick"
    :type="type"
    :disabled="loading"
    class="main_app_button text-capitalize p-2 d-flex justify-content-center align-items-center gap-2"
    :class="{ with_filter: filter }"
  >
    <slot></slot>
    <span v-if="!loading">{{ labelText }}</span>
    <v-progress-circular
      v-if="loading"
      color="white"
      indeterminate
      :size="25"
    ></v-progress-circular>
  </Button>
</template>

<script>
export default {
  name: "AppButton",
  emits: ["click"],
  props: {
    isLink: {
      type: Boolean,
      default: false,
    },
    toRoute: {
      type: String,
    },
    type: {
      type: String,
      default: "btn",
    },
    labelText: {
      type: String,
      default: "Save",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handlerClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.main_app_button {
  min-width: 150px;
  background-color: $primary_color;
  // border: 1px solid $primary_color;
  color: $white;
  border-radius: 10px;
  font-size: 17px;
  transition: $transition;
  .login_icon {
  }
  &.cancel {
    background-color: rgb(var(--v-theme-error)) !important;
  }
  &.with_filter {
    min-width: 100px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    color: $text_color;
  }
}
</style>
