import { createStore } from "vuex";
import auth from "./modules/user/auth";
import branches from "./modules/branches/branches";
import employees from "./modules/employees/employees";
import vacations from "./modules/vacations/vacations";
import departments from "./modules/departments/departments";
import orders from "./modules/orders/orders";
import jobs from "./modules/jobs/jobs";
import roles from "./modules/roles/roles";
import shared from "./modules/shared/shared";
import axiosInstance from "@/utils/axios";
export default createStore({
  state: {
    snackbar: {
      show: false,
      text: "",
      color: "primary",
    },
    showSidebar: false,
    privacy: {},
    terms: {},
    support: {},
  },
  getters: {
    privacyData(state) {
      return state.privacy;
    },
    termsData(state) {
      return state.terms;
    },
    supportData(state) {
      return state.support;
    },
  },
  mutations: {
    showSnackbar(state, { text, color }) {
      state.snackbar.text = text;
      state.snackbar.color = color;
      state.snackbar.show = true;
    },
    SET_Privacy(state, list) {
      state.privacy = list;
    },
    SET_TERMS(state, list) {
      state.terms = list;
    },
    SET_SUPPORT(state, list) {
      state.support = list;
    },
  },
  actions: {
    hideSnackbar({ state }) {
      state.snackbar.show = false;
    },
    toggleSidebar({ state }) {
      state.showSidebar = !state.showSidebar;
    },
    // Handler Get Data
    async getPrivacy({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`privacy`)
          .then((response) => {
            commit("SET_Privacy", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getTerms({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`terms`)
          .then((response) => {
            commit("SET_TERMS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getSupport({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`support`)
          .then((response) => {
            commit("SET_SUPPORT", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {
    auth,
    branches,
    employees,
    vacations,
    departments,
    orders,
    shared,
    jobs,
    roles,
  },
});
