import axiosInstance from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    branchesList: [],
    branchDetails: [],
    branchWorkHours: {},
  },
  getters: {
    getBranchesList(state) {
      return state.branchesList;
    },
    getBranchDetails(state) {
      return state.branchDetails;
    },
    getBranchWorkHours(state) {
      return state.branchWorkHours;
    },
  },
  mutations: {
    SET_BRANCHES_LIST(state, list) {
      state.branchesList = list;
    },
    SET_BRANCHES_Details(state, list) {
      state.branchDetails = list;
    },
    SET_BRANCHES_WORK_HOURS(state, items) {
      state.branchWorkHours = items;
    },
  },
  actions: {
    // Handler Get All Branches
    async getBranches({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`jobs`, {
            params: {
              limit: payload ? payload.limit : 10,
              offest: 1,
              sort: "ASC",
              paginate: "true",
              field: "id",
              deleted: "all", //all,deleted
              resource: "all", //all,custom
              resource_columns: ["id"],
              columns: payload ? payload.columns : [],
              operand: payload ? payload.operand : [],
              column_values: payload ? payload.column_values : [],
            },
          })
          .then((response) => {
            commit("SET_BRANCHES_LIST", response.data.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Get Branch
    async getBranchDetails({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`jobs/${id}`)
          .then((response) => {
            commit("SET_BRANCHES_Details", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async editDepartment({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .put(`jobs/${payload.id}`, payload.updateData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Handler Add Branch
    async addBranch({ commit }, payload) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .post(`jobs`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Delete Branch
    async deleteBranch({ commit }, id) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .delete(`jobs/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Handler Get All Branches
    async exportData({ commit }) {
      return await new Promise((resolve, reject) => {
        axiosInstance
          .get(`jobs/export`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
