import MainTitle from "./MainTitle.vue";
import AuthLayout from "../layout-pages/AuthLayout.vue";
import AppButton from "./AppButton.vue";
import MainActivities from "./MainActivities.vue";
import SectionDefinition from "./SectionDefinition.vue";
import WelcomeSvg from "./WelcomeSvg.vue";
import AppSnackbar from "./AppSnackbar.vue";
import NoDataAvailable from "./NoDataAvailable.vue";
import AppDatePicker from "./AppDatePicker.vue";
import DeleteConfirm from "./DeleteConfirm.vue";
import SearchAndFilter from "./SearchAndFilter.vue";
import BreadcrumbPaths from "./BreadcrumbPaths.vue";

export default [
  MainTitle,
  AuthLayout,
  AppButton,
  MainActivities,
  SectionDefinition,
  WelcomeSvg,
  AppSnackbar,
  NoDataAvailable,
  AppDatePicker,
  DeleteConfirm,
  SearchAndFilter,
  BreadcrumbPaths,
];
