<template>
  <section
    class="main_layout_content"
    :class="{ language_switched_to_ar: getLang == 'ar' }"
  >
    <div class="wrapper_dashboard bg-white">
      <SidebarDashboard></SidebarDashboard>
    </div>
    <div class="wrapper_content py-4 bg-white">
      <div class="container">
        <main class="py-3 display_content px-md-5">
          <TopNavbarWithDescription></TopNavbarWithDescription>
          <div
            class="d-flex main_layout_separate justify-content-between gap-4"
          >
            <div class="separate_of_content flex-grow-1">
              <router-view v-slot="{ Component }">
                <component :is="Component" />
              </router-view>
            </div>
            <div class="separate_of_users bg-gray-light p-4 rounded">
              <!-- Date And Time -->
              <div class="bg-white rounded p-3 text-center">
                <h6 class="title_color mb-2 text-capitalize">
                  {{ $t("today date") }}
                </h6>
                <span class="d-block fs-6 description_color mb-2">{{
                  currentDate
                }}</span>
                <span class="d-block fs-6 description_color">{{
                  currentTime
                }}</span>
              </div>

              <!-- Users -->
              <div class="mt-5 global_users_container">
                <div class="mb-3">
                  <h5 class="mb-0 title_color text-capitalize">
                    {{ $t("last activities") }}
                  </h5>
                  <p class="global_sub_title mb-0 mt-1">
                    {{
                      $t(
                        "The latest operations performed on the system during the last week"
                      )
                    }}
                  </p>
                </div>
                <div class="main_user_container">
                  <div
                    class="every_user d-flex justify-content-between align-items-end py-3 px-2 rounded"
                    v-for="user in dashboard.recent_activities"
                    :key="user.id"
                  >
                    <div class="part_one d-flex align-items-center gap-1">
                      <div class="img_wrapper">
                        <img
                          class="img"
                          v-if="user.image"
                          :src="user.image"
                          :alt="user.employee_name"
                        />
                        <v-icon
                          v-else
                          icon="mdi mdi-account icon_nav_bar"
                        ></v-icon>
                      </div>
                      <div>
                        <h6 class="title_color mb-1 text-capitalize name">
                          {{ user.employee_name }}
                        </h6>
                        <div class="status d-flex gap-1 align-items-center">
                          <!-- <img :src="user.icon" :alt="user.name" /> -->
                          <v-icon
                            icon="mdi mdi-logout icon text-error"
                          ></v-icon>
                          <span class="text-error">{{ user.status }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="part_two">
                      <span class="description_color">{{ user.time }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Vacations -->
              <div>
                <div
                  class="mt-5 mb-3 d-flex align-items-center justify-content-between"
                >
                  <div>
                    <h5 class="mb-0 title_color text-capitalize">
                      {{ $t("Next vacations") }}
                    </h5>
                    <p class="global_sub_title mb-0 mt-1">
                      {{ $t("You can allocate vacation days") }}
                    </p>
                  </div>
                  <router-link :to="{ name: 'VacationsView' }">
                    <span class="bg-white rounded p-2 cursor-pointer">
                      <v-icon icon="mdi mdi-cog description_color"></v-icon>
                    </span>
                  </router-link>

                </div>
                <div class="vacations_list">
                  <div class="every_vacation d-flex gap-2 py-3 px-2">
                    <div class="days bg-white text-center rounded p-2">
                      <span class="d-block big_font_size">09</span>
                      <span class="d-block big_font_size">Sunday</span>
                    </div>
                    <div class="">
                      <h6 class="mb-2 big_font_size">Eid al-Adha</h6>
                      <span class="d-block description_color font_size"
                        >Saturday, May 25, 2024</span
                      >
                    </div>
                  </div>
                  <div class="every_vacation d-flex gap-2 py-3 px-2">
                    <div class="days bg-white text-center rounded p-2">
                      <span class="d-block big_font_size">09</span>
                      <span class="d-block big_font_size">Sunday</span>
                    </div>
                    <div class="">
                      <h6 class="mb-2 big_font_size">Eid al-Adha</h6>
                      <span class="d-block description_color font_size"
                        >Saturday, May 25, 2024</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted, computed } from "vue";
import SidebarDashboard from "@/components/layout-pages/SidebarDashboard.vue";
import TopNavbarWithDescription from "@/components/layout-pages/TopNavbarWithDescription.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default defineComponent({
  name: "MainLayout",
  components: {
    SidebarDashboard,
    TopNavbarWithDescription,
  },
  setup() {
    const currentDate = ref("");
    const currentTime = ref("");
    const store = useStore();
    const updateDateTime = () => {
      const now = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "2-digit",
      };
      currentDate.value = new Intl.DateTimeFormat("en-US", options).format(now);
      currentTime.value = now.toLocaleTimeString();
    };

    const users = ref([
      {
        id: 1,
        name: "mohamed alm3rag",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "Dismissal registration",
        time: "10:56 AM",
      },
      {
        id: 2,
        name: "mostafa ali",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "Dismissal registration",
        time: "10:56 AM",
      },
      {
        id: 3,
        name: "mohamed alm3rag",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "Dismissal registration",
        time: "10:56 AM",
      },
      {
        id: 4,
        name: "mostafa ali",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "Dismissal registration",
        time: "10:56 AM",
      },
      {
        id: 5,
        name: "mohamed alm3rag",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "Dismissal registration",
        time: "10:56 AM",
      },
      {
        id: 6,
        name: "mostafa ali",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "Dismissal registration",
        time: "10:56 AM",
      },
      {
        id: 7,
        name: "mohamed alm3rag",
        img: require("@/assets/images/group.png"),
        icon: require("@/assets/images/icon.png"),
        status: "success",
        status_name: "Dismissal registration",
        time: "10:56 AM",
      },
    ]);
    const { locale } = useI18n();
    const getLang = computed(() => {
      return locale.value;
    });
    const dashboard = computed(() => {
      return store.getters["shared/getDashboard"];
    });
    onMounted(() => {
      store.dispatch("shared/getDashboardData");
      updateDateTime();
      const interval = setInterval(updateDateTime, 1000);
      onUnmounted(() => clearInterval(interval));
    });
    return {
      currentDate,
      currentTime,
      updateDateTime,
      users,
      locale,
      getLang,
      dashboard,
    };
  },
});
</script>

<style lang="scss" scoped>
.main_layout_content {
  display: flex;
  &.language_switched_to_ar {
    direction: rtl;
  }
  .wrapper_content {
    flex-grow: 1;
    .display_content {
      margin-top: 100px;
    }
  }

  .main_layout_separate {
    .separate_of_users {
      width: 300px;
    }
  }
  .vacations_list {
    .big_font_size {
      font-size: 0.8rem;
    }
    .font_size {
      font-size: 0.7rem;
    }
  }
  .main_user_container {
    max-height: 70vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #555 !important;
      border-radius: 10px;
    }
  }
}
</style>
