import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AuthenticationLayout from "../components/layout-pages/AuthenticationLayout.vue";
import MainLayout from "../components/layout-pages/MainLayout.vue";

import { getStatus, getToken } from "@/plugins/cookies";
const routes = [
  {
    path: "/auth",
    name: "AuthenticationLayout",
    component: AuthenticationLayout,
    children: [
      {
        path: "/login",
        name: "LoginView",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/auth-pages/LoginView.vue"
          ),
      },
      {
        path: "/sign-up",
        name: "RegisterView",
        component: () => import("../views/auth-pages/RegisterView.vue"),
      },
      {
        path: "/confirm-email",
        name: "ConfirmEmail",
        component: () => import("../views/auth-pages/ConfirmEmail.vue"),
      },
      {
        path: "/check-code/:email?/:forgot?",
        name: "CheckCode",
        component: () => import("../views/auth-pages/CheckCode.vue"),
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ "../views/auth-pages/ForgotPassword.vue"
          ),
      },
      {
        path: "/reset-password/:email",
        name: "ResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ "../views/auth-pages/ResetPassword.vue"
          ),
      },
      {
        path: "/apology",
        name: "ApologyPage",
        component: () =>
          import(
            /* webpackChunkName: "ApologyPage" */ "../views/website-privacy-pages/ApologyPage.vue"
          ),
      },
      {
        path: "/no-subscription",
        name: "NoSubscriptionPage",
        component: () =>
          import(
            /* webpackChunkName: "NoSubscriptionPage" */ "../views/website-privacy-pages/NoSubscriptionPage.vue"
          ),
      },
      {
        path: "/privacy-policy",
        name: "PolicyView",
        component: () =>
          import(
            /* webpackChunkName: "privacy-policy" */ "../views/website-privacy-pages/PolicyView.vue"
          ),
      },
      {
        path: "/terms-conditions",
        name: "TermsView",
        component: () =>
          import(
            /* webpackChunkName: "terms-conditions" */ "../views/website-privacy-pages/TermsView.vue"
          ),
      },
      {
        path: "/customer-support",
        name: "SupportedView",
        component: () =>
          import(
            /* webpackChunkName: "customer-support" */ "../views/website-privacy-pages/SupportedView.vue"
          ),
      },
    ],
  },
  {
    path: "/main",
    name: "MainLayout",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "HomeView",
        component: HomeView,
        meta: { requiresAuth: true },
      },
      {
        path: "/about",
        name: "AboutView",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/setting",
        name: "AccountSettings",
        component: () =>
          import(
            /* webpackChunkName: "setting" */ "../views/AccountSettings.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/complete-dashboard-settings",
        name: "AccountDetailsView",
        component: () =>
          import(
            /* webpackChunkName: "setting" */ "../views/AccountDetailsView.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/reports",
        name: "ReportsView",
        component: () =>
          import(/* webpackChunkName: "reports" */ "../views/ReportsView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/branches",
        name: "BranchesView",
        component: () =>
          import(
            /* webpackChunkName: "branches" */ "../views/BranchesView.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/all-branches",
        name: "BranchView",
        component: () =>
          import(/* webpackChunkName: "branches" */ "../views/BranchView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/branches/:id",
        name: "ShowBranch",
        component: () =>
          import(
            /* webpackChunkName: "branches" */ "../components/branches/ShowBranch.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/employees",
        name: "EmployeesView",
        component: () =>
          import(
            /* webpackChunkName: "employees" */ "../views/EmployeesView.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/employees/add-employ/:id?",
        name: "AddEmploye",
        component: () =>
          import(
            /* webpackChunkName: "add employ" */ "../components/employees/AddEmploye.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/employees/:id",
        name: "ShowEmployee",
        component: () =>
          import(
            /* webpackChunkName: "add employ" */ "../components/employees/ShowEmployee.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/departments",
        name: "DepartmentsView",
        component: () =>
          import(
            /* webpackChunkName: "departments" */ "../views/DepartmentsView.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/jobs",
        name: "JobsView",
        component: () =>
          import(/* webpackChunkName: "departments" */ "../views/JobsView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/work-hours",
        name: "WorkHours",
        component: () =>
          import(
            /* webpackChunkName: "departments" */ "../views/WorkHours.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/roles",
        name: "RolesView",
        component: () =>
          import(
            /* webpackChunkName: "departments" */ "../views/RolesView.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/departments/:id",
        name: "ShowDepartment",
        component: () =>
          import(
            /* webpackChunkName: "departments" */ "../components/departments/ShowDepartment.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/orders",
        name: "OrdersView",
        component: () =>
          import(/* webpackChunkName: "orders" */ "../views/OrdersView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/orders/:id/:model",
        name: "ShowOrder",
        component: () =>
          import(
            /* webpackChunkName: "orders" */ "../components/orders/ShowOrder.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/time-of-work",
        name: "TimeOfWork",
        component: () =>
          import(
            /* webpackChunkName: "time-of-work" */ "../views/TimeOfWork.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/vacations",
        name: "VacationsView",
        component: () =>
          import(
            /* webpackChunkName: "vacations" */ "../views/VacationsView.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/vacations/:id",
        name: "ShowVacation",
        component: () =>
          import(
            /* webpackChunkName: "vacations" */ "../components/vacations/ShowVacation.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/company-data",
        name: "CompanyData",
        component: () =>
          import(
            /* webpackChunkName: "company-data" */ "../views/CompanyData.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/general-setting",
        name: "GeneralSettings",
        component: () =>
          import(
            /* webpackChunkName: "general-setting" */ "../views/GeneralSettings.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Scroll to the top of the page
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = getToken();
  const status = getStatus();
  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/login"); 
  } else if (isAuthenticated) {
    if (status === 'active') {
      next();
    } else if (subscriptionStatus) {
      next({ name: 'ApologyPage' });
    } else {
      next("/login"); 
    }
  } else {
    next();
  }
});
export default router;
