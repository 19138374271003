import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { getToken } from "@/plugins/cookies";

window.Pusher = Pusher;

const userToken = getToken();

const echo = new Echo({
    broadcaster: "pusher",
    key: "30686cfe1f3603accf6f",
    cluster: "eu",
    wsPort: 6001, 
    wssPort: 6001, 
    forceTLS: true,
    disableStats: true,
    authEndpoint: "https://api.idone.sa/broadcasting/auth",
    auth: {
        headers: {
            Authorization: "Bearer " + userToken,
        },
    },
});

export default echo;
