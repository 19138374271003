<template>
  <div class="main_app_page bg-container-color">
    <router-view />
  </div>
  <AppSnackbar></AppSnackbar>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
export default defineComponent({
  setup() {
    onMounted(() => {
      // document.documentElement.style.setProperty("--primary-color", "#ef4136");
    });
  },
});
</script>

<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,500&display=swap");
// @import "./assets/css/custom.css";
.main_app_page {
  // font-family: "Roboto", sans-serif;
  // background-color: var(--v-theme-container-color) !important;
}
</style>
