<template>
  <header
    :class="[
      'main_sidebar',
      'position-relative',
      'bg-white',
      { sidebar_closed: showSidebar },
    ]"
  >
    <!-- <span
      @click="openSidebar"
      class="nav_bars_container cursor-pointer align-items-center justify-content-center rounded shadow-sm"
      :class="[{ 'd-flex': showSidebar, 'd-none': !showSidebar }]"
    >
      <i class="pi pi-align-justify nav_bars"></i>
    </span> -->
    <nav
      :class="[
        'main_navbar',
        'bg-white',
        { sidebar_closed: showSidebar },
        { language_switched_to_ar: getLang == 'ar' },
      ]"
    >
      <div class="logo_wrapper p-2">
        <router-link class="link" :to="{ name: 'HomeView' }">
          <img
            class="logo"
            src="@/assets/images/new logo.jpeg"
            alt="logo"
            draggable="false"
          />
        </router-link>
        <!-- <v-icon
          icon="mdi mdi-menu cursor-pointer icon_nav_bar"
          @click="toggleSidebar"
        ></v-icon> -->
      </div>
      <ul class="list-unstyled links_list">
        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'HomeView' }"
          >
            <v-icon icon="mdi-home"></v-icon>
            <span>{{ $t("Home") }}</span>
          </router-link>
        </li>

        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'OrdersView' }"
            ><v-icon icon="mdi-order-bool-ascending-variant"></v-icon
            ><span>{{ $t("Orders") }}</span></router-link
          >
        </li>
        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'BranchesView' }"
          >
            <v-icon icon="mdi mdi-source-branch-check"></v-icon>
            <span>{{ $t("Branches") }}</span>
          </router-link>
        </li>
        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'VacationsView' }"
          >
            <v-icon icon="mdi mdi-car-brake-hold"></v-icon>
            <span>{{ $t("Annual vacations") }}</span>
          </router-link>
        </li>
        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'EmployeesView' }"
          >
            <v-icon icon="mdi mdi-account-group"></v-icon>
            <span>{{ $t("Employees") }}</span>
          </router-link>
        </li>
        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'DepartmentsView' }"
          >
            <v-icon icon="mdi mdi-account-group"></v-icon>
            <span>{{ $t("Departments") }}</span>
          </router-link>
        </li>
        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'JobsView' }"
          >
            <v-icon icon="mdi mdi-briefcase"></v-icon>
            <span>{{ $t("Jobs") }}</span>
          </router-link>
        </li>
        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'WorkHours' }"
          >
            <v-icon icon="mdi mdi-clock"></v-icon>
            <span>{{ $t("Work Hours") }}</span>
          </router-link>
        </li>
        <!-- <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'RolesView' }"
          >
            <v-icon icon="mdi mdi-briefcase"></v-icon>
            <span>{{ $t("Roles") }}</span>
          </router-link>
        </li> -->

        <li class="every_link_item p-2">
          <router-link
            class="link text-capitalize p-2 rounded"
            :to="{ name: 'AccountSettings' }"
          >
            <v-icon icon="mdi mdi-account-key"></v-icon>
            <span>{{ $t("company details") }}</span>
          </router-link>
        </li>
      </ul>
      <div class="p-3 complete_dashboard_settings rounded text-center">
        <img
          class="img"
          src="../../assets/images/sidebar.jpg"
          alt="dashboard settings"
        />
        <div class="d-flex align-items-center justify-content-between mt-3">
          <span class="text-white">{{ $t("Dashboard settings") }}</span>
          <span class="text-white">84%</span>
        </div>
        <div class="progress_bar mt-2">
          <span class="d-block progress_color h-100"></span>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "SidebarDashboard",

  setup() {
    const store = useStore();
    const { locale } = useI18n();
    const showSidebar = computed(() => {
      return store.state.showSidebar;
    });
    const getLang = computed(() => {
      return locale.value;
    });
    const toggleSidebar = () => {
      store.dispatch("toggleSidebar");
    };

    return { showSidebar, locale, getLang, toggleSidebar };
  },
});
</script>

<style lang="scss" scoped>
.main_sidebar {
  width: 280px;
  height: 100vh;
  // background: var(--sidebar-color) !important;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
  .nav_bars_container {
    display: none;
  }
  &.sidebar_closed {
    width: 0;
    margin-right: 0;
    .nav_bars_container {
      position: fixed;
      transition: all 0.3s ease-in-out;
      width: 25px;
      height: 25px;
      z-index: 3000;
      left: 10px;
      top: 17px;
      display: block;
      .nav_bars {
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        .nav_bars {
          color: rgb(var(--v-theme-title-color)) !important;
        }
      }
    }
  }
  .main_navbar {
    overflow-y: auto;
    position: fixed;
    padding: 0 10px;
    border-right: 1px solid #e7e7e7;
    width: 280px;
    height: 100vh;
    left: 0;
    transition: all 0.3s ease-in-out;
    z-index: 2000;
    &.language_switched_to_ar {
      left: auto;
      right: 0;
    }
    .nav_bars_container {
      position: absolute;
      right: 35px;
      transition: all 0.3s ease-in-out;
      width: 25px;
      height: 25px;
      z-index: 3000;
      top: 40px;
      .nav_bars {
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        .nav_bars {
          // color: var(--secondary-color);
        }
      }
    }
    &.sidebar_closed {
      width: 0;
      padding: 0;
      overflow: hidden;
      // .nav_bars_container {
      //   right: -35px;
      //   top: 15px;
      // }
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #555 !important;
      border-radius: 10px;
    }
    .logo_wrapper {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        height: 70px;
      }
    }
    .links_list {
      .every_link_item {
        .link {
          display: flex;
          gap: 10px;
          align-items: center;
          // color: $routes_color;
          color: rgb(var(--v-theme-title-color)) !important;
          text-decoration: none;
          transition: color 0.2s ease-in-out;
          .v-icon,
          span {
            transition: color 0.2s ease-in-out;
          }
          &.router-link-exact-active,
          &:hover {
            color: rgb(var(--v-theme-primary)) !important;
            // color: #fff !important;
            // background-color: rgb(var(--v-theme-primary)) !important;
            // .v-icon,
            // span {
            //   transform: translateX(5px);
            // }
          }
        }
      }
    }
  }
  .complete_dashboard_settings {
    background-image: linear-gradient(#ff8e01, #ff8e01, #fe2e00);

    .img {
      max-width: 100%;
      height: 100px;
    }
    .progress_bar {
      border: 2px solid #fff;
      border-radius: 10px;
      height: 10px;
      display: flex;
      justify-content: end;
      background: #fff;
      .progress_color {
        width: 84%;
        border-radius: 10px;
        background-image: linear-gradient(#ff8e01, #ff8e01, #fe2e00);
      }
    }
  }
  .icon_nav_bar {
    transition: all 0.3s;
    &:hover {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}
</style>
