<template>
  <v-snackbar
    v-model="snackbar.show"
    multi-line
    elevation="0"
    :timeout="2000"
    :color="snackbar.color"
  >
    <span class="text">{{ snackbar.text }}</span>
    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="hideSnackbar"
        ><v-icon icon="mdi mdi-close"></v-icon
      ></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "AppSnackbar",

  setup() {
    const store = useStore();
    const snackbar = computed(() => {
      return store.state.snackbar;
    });
    const hideSnackbar = () => {
      store.dispatch("hideSnackbar");
    };
    return {
      snackbar,
      hideSnackbar,
    };
  },
});
</script>

<style lang="scss">
.v-snackbar--multi-line .v-snackbar__wrapper {
  top: 20px !important;
  right: 20px !important;
  left: auto !important;
  bottom: auto !important;
  height: auto !important;
  transform: translateX(0) !important;
  max-width: 450px;
}
.v-snackbar__content {
  text-align: center !important;
  color: #fff;
}
.text {
}
</style>
